#landing-page {
  padding: 30px 20px 20px 20px;
  line-height: 1;
  margin: -10px 0 0 0;
  border-top: 6px solid #4b4b4b;
  border-bottom: 1px solid #4b4b4b;
  font-family: georgia, times, serif;
  }

#landing-page h3 {
  letter-spacing: .05em;
  font-size: 1.8em;
  color: #fff;
  }

  @media(min-width: 35em ) {
    #landing-page h3 {
      margin: 15px 0 .75em 0;
      }
  }

#landing-page p {
  color: #c0c0c0;
  letter-spacing: .07em;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.4;
  }

#landing-page .salutations {
  text-align : right;
  color: #c0c0c0;
  }

#landing-page .salutaitons em,
#landing-page .salutations span a {
  font-size: 1.4em;
  color: #c0c0c0;
  }

#landing-page .salutations em {
  font-style: italic; color: #fff;
}

#landing-page .salutations span a {
  text-decoration: none;
  border-bottom: 1px solid #4b4b4b;
  }

#landing-page .salutations span a:hover {
  color: #fff;
  }
