header {
 display: flex;
 align-items: center;
 background: var(--bg-main);
 border-bottom: 1px solid #666;
 flex-wrap: wrap;
}

@media (min-width: 35em) {
  header {
    flex-wrap:nowrap;
  }
}

header img {
  height: 5.5em;
  max-height: 85px;
}

header h3 {
  margin-top: 15px;
  font-size: 1.50em;
  text-transform: uppercase;
}

header h4 {
  color: var(--text-sub);
  text-transform: uppercase;
  font-size: .65em;
}


header > div {
  text-align: center;
  flex: 1 1 auto;
  height: 75px;
  padding-left: 15px;
  padding-right: 15px;
  border-right: 1px solid #333;
}

@media(min-width: 35em) {
  header > div {
    flex: 0 1 auto;
  }
}

header > div:hover {
  background: #313131;
}

header .logo {
  flex: 0 1 auto;
  padding-left: 0;
  padding-right: 0;
  border-right: 0;
}

header .company {
  text-align: left;
  display: none;
}

header .spacer {
  display: none;
}
header .spacer:hover {
  background: var(--bg-main);
}

header .rip {
 display: none;
 padding-left: 0;
 padding-right: 0;
 border-right: 0;
}


@media(min-width: 35em) {
  header .rip {
    flex: 0 1 auto;
    display: block;
  }
  header .spacer {
    flex: 1 1 auto;
    display: block;
  }

  header .company {
    text-align: left;
    display: block;
  }
}


/* header {
  height: 78px;
  background: transparent url('../../images/header.gif') top left no-repeat;
  }

header h1 a{
  width: 253px;
  height: 78px;
  display: block;
  float: left;
  text-indent: -5000px;
}

@media (min-width: 35em) {
  header h1 a{
    width: 353px;
  }
}

header h2, header h3 {display: none;}

header .main-tools {
  float: left;
  }

header .main-tools .browse-all {
  width: 185px;
  height: 73px;
  float: left;
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  }

header .main-tools .browse-all a{
  display: block;
  width: 185px;
  height: 73px;
  text-indent: -5000px;
  background: var(--bg-main) url('../../images/nav.png') 0 0 no-repeat;
  }

header .main-tools .browse-all a:hover{
  background: var(--border) url('../../images/nav.png') 0 -73px no-repeat;
  }

header .main-tools .submit-a-toilet {
  width: 173px;
  height: 73px;
  float: left;
  border-right: 1px solid var(--border);
  }

header .main-tools .submit-a-toilet a {
  display: block;
  width: 173px;
  height: 73px;
  text-indent: -5000px;
  background: var(--bg-main) url('../../images/nav.png') -185px 0 no-repeat;
  }

header .main-tools .submit-a-toilet a:hover {
  background: var(--border) url('../../images/nav.png') -185px -73px no-repeat;
  } */
