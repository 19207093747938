.ragged {
  width: 100%;
  height: 18px;
  background: transparent url('../../images/ragged.gif') top left no-repeat;
}

footer {
  background: var(--bg-alt);
  text-align: center;
  height: 3em;
  margin-top: .25em;
  font-size: 1.5em;

}

footer a,
footer a:active,
footer a:visited,
footer a:link {
  color: #333;
  margin: auto .5em;
}

footer a:hover {
  text-decoration: underline;
}
