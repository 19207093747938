.auto-locate {
  flex: 1 1 auto;
  text-align: center;
  padding: .5em;
}

.auto-locate button {
  font-size: 1.3em;
  background: #999;
  background-image: linear-gradient(#888, #999, #888);
  color: #fff;
  padding: .25em;
  border-radius: .25em;
  padding: .25em;
  height: 2em;
}

.auto-locate button i{
  margin-right: .25em;
}

.auto-locate span {
  display: block;
  font-size: .75em;
}
