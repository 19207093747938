.toilet-form .form-errors {
  background: pink;
  border: .25em solid red;
  padding: 1em;
  color: red;
  margin: .5em auto;
}

.toilet-form .form-errors ul {
  padding: 0 1em;
}

.toilet-form .recaptcha {
}

.toilet-form .locate-me {
  padding-bottom: .25em;
  margin-left: 1em;
  font-size: .7em;
  border-radius: .25em;
  background: 0;
  border: 0;
  color: lightgreen;
}
