.error-404 {
  text-align: center;
}

.error-404 h1 {
  font-size: 15em;
}

.error-404 p {
  font-size: 2em;
}
