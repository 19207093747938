.locater-box {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.locater-box .or {
  padding: .1em .5em;
  text-align: center;
  font-size: 1.5em;
  color: var(--text-alt);
}


.locater-box .or .bar {
  color: var(--text-sub);
  display: none;
}

.locater-box .toggle-button {
  color: #fff;
  height: 2em;
  font-size: .75em;
  width: 11em;
  margin: .55em auto;
  border-radius: 6px;
  background-color: transparent;
}

.locater-box .toggle-button:hover {
  background: #666;
}

@media(min-width: 640px) {
  .locater-box {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .locater-box .or {
    display: flex;
    padding: 0 .5em;
    flex-direction: column;
    font-size: 1em;
  }

  .locater-box .or .bar {
    display: inline-block;
  }
}
