.toilet-result {
  padding: 10px 0;
  border-bottom: 1px solid var(--border);
  background: var(--bg-main);
  clear: both;
  }

  /* .toilet-result:hover {
    background: #333;
  } */

.listings-title{
  color: #8c8c8c;
  font-size: 1.3em;
  text-decoration: none;
  font-weight: bold;
  margin: 10px 0 0 20px;
  }

h3.toilet-title a, #primary-toilet-information h2  {
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: bold;
  }

h3.toilet-title a:hover {
  border-bottom: 1px solid #79868b;
  }

.core-details span, #primary-toilet-information h3 {
  text-transform: uppercase;
  font-size: .8em;
  }

.main-toilet-info {
  max-width: 500px;
  float: left;
  }

p.description {
  color: #bfcacf;
  padding: 5px 0 0 0;
  font-size: 1em;
  line-height: 1.2;
  font-weight:200;
  }

.toilet-result img {float: right; width:100px;}
