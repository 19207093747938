#press-page {
  padding: 20px;
  color: #e1e1e1;
}

#press-page  p {
  border-bottom: 1px solid #4d4d4d;
  padding: 10px 0;
}

#press-page  ul {
  padding: 20px 0;
}

#press-page  ul  li {
  padding: 5px 0 5px 35px;
  background: #232323 url('../images/twitter.gif') left center no-repeat;
  border-bottom: 1px solid #4d4d4d;
}
