.toilet-header {
  display: flex;
  width: 100%;
}

.toilet-header .info {
  flex: 2 1 auto;

}

.toilet-header .info .cross-street {
  font-size: .75em;

}
