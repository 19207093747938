.comment {

}

.comment-header {
  font-size: .85em;
  border-bottom: 1px solid #333;
  padding: .25em;
}

.comment-header span {
  color: pink;
}

.comment-body::before {
  content: open-quote;
  font-family: serif;
  font-size: 1.2em;
}

.comment-body::after {
  content: close-quote;
  font-family: serif;
  font-size: 1.2em;
}
