.location-form{
  flex: 1 1 auto;
  padding: 0 .25;
}

.location-form input[type="text"] {
  width: 100%;
  font-size: 1.2em;
  height: 2em;
  padding: .25em;

}
@media (min-width: 35em) {
  .location-form{
    padding: .5em;
  }

}

.location-form span {
  font-size: .75em;
  color: var(--text-sub);
}
