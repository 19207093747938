.current-location {
  text-align: center;
  width: 100%;
  flex: 1 0 100%;
}

.current-location .coords {
  display: block;
  font-size: .75em;
}

.current-location .coords i {
  color: var(--text-alt);
}

.current-location .address {
  display: block;
  font-size: 1.25em;
  color: pink;
}
