#about-page {
  padding: 0 20px 20px 20px;
  }

#about-page h3 {
  font-weight: bold;
  }

#about-page p {
  color: #9ca7ac;
  padding: 0 0 15px 0;
  font-size: .9em;
  line-height: 1.5;
  }

#about-page h1, #press h1 {
  color: #8c8c8c;
  font-size: 1.3em;
  text-decoration: none;
  font-weight: bold;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  }

.about-section {
  padding: 20px 0;
  border-bottom: 1px solid #4a4a4a;
}

.press-image {
  padding: 0 0 5px 0;
}

.press-image span {
  color: #9c9c9c;
  font-size: .8em;
}


.press-image img {
  padding: 5px;
  background: #464646;
  border: 1px solid #5d5d5d;
  margin: 0 0 5px 0;
}

em.tagline {
  font-size: 2.5em;
  color: #5a5a5a;
  font-family: georgia, times, serif;
  font-style: italic;
  text-align: center;
  display: block;
}
