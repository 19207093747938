.toilet {
  display: flex;
  flex-wrap: wrap;
}

.toilet .info {
  flex: 4 1 auto;
}

.toilet .photo {
  flex: 1 1 auto;
  text-align: right;
}

.toilet .name {
  display: block;
}

.toilet .name h3 {
  font-size: 1.5em;
}

.toilet .address-line1 {
  display: block;
}

.toilet .address-line2 {
  display: block;
  text-transform: uppercase;
}

.toilet .cross-street {
  font-size: .75em;
}

.toilet .description {
  width: 100%;
  display: block;
  font-size: 1em;
  color: var(--text-alt);
  margin: 1em auto;
}

.toilet .hours {
  display: block;
  width: 100%;
  margin: 1em auto;
}

.toilet .hours span {
  font-weight: 600;
}

.toilet .ratings,
.toilet .amenities {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1em;
}
