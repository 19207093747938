
:root {
  box-sizing: border-box;
  font-size: calc(1vw + 0.7em);
  font-weight: 300;
  --bg-main: #232323;
  --bg-alt: #e9e9e1;
  --text-main: #fff;
  --text-alt: #c9c9c9;
  --text-sub: #969696;
  --border: #4b4b4b;
}

@media(min-width: 45em) {
  :root {
    font-size: 20px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background: var(--bg-alt);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:visited, a:link, a:active {
  color: var(--text-main);
  text-decoration: none;
}

.app-container {
  color: var(--text-main);
  max-width: 768px;
  margin: auto;
}

.container {
  background: var(--bg-main);
  padding: .75em;
}

@media (min-width: 35em ) {
  .container {
    padding: 1em;
  }
}


label {
  font-size: 1.3em;
}

form .file-group input[type=file] {
  -webkit-appearance: none;
  background: #999;
  padding: .25em;
  display: block;
  font-size: 16px;
}

form .checkbox-group,
form .text-group,
form .radio-group,
form .submit-group,
form .select-group  {
  margin: 1em auto;
}

form .radio-group {
  border-bottom: 1px solid var(--border);
}

form span {
  font-size: .65em;
  display: block;
}

form .text-group input {
  display: block;
  height: 2.5em;
  font-size: 16px;
  padding: .5em;
  width: 100%;
}

form .text-group textarea {
  display: block;
  height: 10em;
  padding: .5em;
  width: 100%;
  font-size: 16px;
}

form .select-group select {
  display: block;
  width: 100%;
  max-width: 400px;
  height: 2.5em;
}

form .checkbox-group input {
  margin-right: .6em;
}

form .radio-group input {
  margin-right: 1em;
}



form .submit-group input[type=submit] {
  -webkit-appearance: none;
  background: #999;
  padding: .5em;
  display: block;
  font-size: 1em;
  /* margin: auto; */
}

h1.page-title {
  text-transform: uppercase;
  border-bottom: 1px solid var(--border);
  margin-bottom: .5em;
  color: var(--text-main);
  font-size: 1.65em;
}

h2.page-title {
  text-transform: uppercase;
  border-bottom: 1px solid var(--border);
  margin: .5em auto;
  color: var(--text-main);
  font-size: 1.3em;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.liner {
  width: 100%;

  border-bottom: 6px solid #4b4b4b;
}
