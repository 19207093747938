.toilet-rating {
  color: pink;
  font-size: 1.5em;
}

.toilet-rating em {
  font-size: .9em;
  text-decoration: none;
  font-style: normal;
  margin-right: .5em;
}

.toilet-rating span {
  font-size: .80em;
}

.toilet-rating .star-empty {
  width: 1em;
  height: 1em;
}

.toilet-rating .star-full {
  width: .75em;
  height: .75em;
}
