#help-page {
  padding: 0 20px 20px 20px;
  }

#help-page h3 {
  font-weight: bold;
  }

#help-page p {
  color: #9ca7ac;
  padding: 0 0 15px 0;
  font-size: .9em;
  line-height: 1.5;
  }

#help-page h1 {
  color: #8c8c8c;
  font-size: 1.3em;
  text-decoration: none;
  font-weight: bold;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  }
